<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="deviceCoordinate">
			<template #ops>
				<el-button
					v-if="hasUpdatePermission"
					type="primary"
					:disabled="!selection.length"
					@click="handleUpdate"
				>
					更新坐标
				</el-button>
			</template>
		</gc-header>
		<div class="content">
			<div class="left">
				<GcRegionTree v-model="form.waterRegionCode" :initSelect="false" />
			</div>
			<div class="run-main">
				<div class="serach">
					<gc-custom-search
						class="search"
						key-word="坐标获取周期"
						type="date"
						datePickerType="daterange"
						width="350px"
						:search.sync="timeCycle"
						:disabled="true"
					></gc-custom-search>
					<gc-custom-search keyWord="间隔" width="180px" :search.sync="intervalDay" :disabled="true">
						<template #unit>天/次</template>
					</gc-custom-search>
					<gc-custom-search keyWord="设备编号" width="300px" :search.sync="form.deviceNo"></gc-custom-search>
					<div class="serach-ops">
						<el-button type="primary" @click="getList(1, apiCoordinateList, null)" v-click-blur>
							查 询
						</el-button>
						<el-button @click="handleReset" v-click-blur>重 置</el-button>
					</div>
					<gc-column-setting
						storageKey="localcolumn-device-coordinate"
						:columns="columns"
						@update-column="data => (columns = data)"
					></gc-column-setting>
				</div>
				<div class="table-show">
					<gc-table
						ref="tableRef"
						:need-type="hasUpdatePermission ? 'selection' : ''"
						:columns="columnList"
						:table-data="tableData"
						:selectable="row => row.coordinateLocke != 1"
						:border="true"
						:showPage="true"
						:total="page.total"
						:page-size="page.size"
						:currentPage="page.current"
						@selectChange="handleSelectionChange"
						@current-page-change="
							currentPage => {
								currentPageChange(currentPage, apiCoordinateList, null)
							}
						"
						@size-change="
							size => {
								page.size = size
								currentPageChange(1, apiCoordinateList, null)
							}
						"
					></gc-table>
				</div>
			</div>
		</div>

		<UpdateFailDialog
			:show.sync="showFailDialog"
			:total-list="selection"
			:fail-list="failList"
			@close="clearSelection"
		/>
	</div>
</template>

<script>
import {
	apiCoordinateList,
	apiCoordinateCycle,
	apiCoordinateUpdate,
	apiCoordinateLocke,
} from '@/apis/deviceCoordinate.api.js'
import common from '../mixins/common.js'
import { mapActions } from 'vuex'
import UpdateFailDialog from './UpdateFailDialog.vue'

export default {
	name: 'deviceCoordinate',
	components: { UpdateFailDialog },
	mixins: [common],
	data() {
		return {
			timeCycle: [],
			intervalDay: 30,
			form: {
				deviceNo: null,
			},
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
					fixed: 'left',
					dragDisable: true,
					disabledSelect: true,
				},
				{
					key: 'waterRegionName',
					name: '所属区域',
				},
				{
					key: 'userTypeName',
					name: '客户类型',
				},
				{
					key: 'longitude',
					name: '经度',
				},
				{
					key: 'latitude',
					name: '纬度',
				},
				{
					key: 'addressName',
					name: '安装地址',
				},
				{
					key: 'coordinateUpdateTime',
					name: '坐标更新时间',
				},
				{
					key: 'coordinateLocke',
					name: '坐标锁定',
					render: (h, row) => {
						return h('el-switch', {
							props: {
								value: row.coordinateLocke == 1 ? true : false,
								disabled: !this.$has('monitor:ledger:coordinate:coordinateLocke'),
							},
							on: {
								change: event => this.handleLocke(event, row),
							},
						})
					},
				},
				{
					key: 'oprate',
					name: '操作',
					fixed: 'right',
					render: (h, row) => {
						return (
							<div>
								<el-button type="text" size="medium" onClick={() => this.toDetail(row)}>
									查看详情
								</el-button>
							</div>
						)
					},
				},
			],
			options: {},
			selection: [],
			failList: [],
			showFailDialog: false,
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
		hasUpdatePermission() {
			return this.$has('monitor:ledger:coordinate:update')
		},
	},
	watch: {
		'form.waterRegionCode': {
			handler(val) {
				if (val) {
					this.getList(1, apiCoordinateList, null)
				}
			},
		},
	},

	mounted() {
		this.getDeviceTypes().then(res => {
			this.$set(this.options, 'deviceTypeId', res)
		})
		this.getTimeCycle()
		const { tenantType, defaultWaterRegionCode } = this.$store.state.user.userInfo
		this.$set(this.form, 'tenantType', tenantType)
		tenantType == 1 && this.$set(this.form, 'waterRegionCode', defaultWaterRegionCode)
	},

	methods: {
		...mapActions({
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getTimeCycle() {
			apiCoordinateCycle().then(res => {
				const startTime = this.dayjs(res.coordinateStartTime).format('YYYY-MM-DD')
				const endTime = this.dayjs(res.coordinateEndTime).format('YYYY-MM-DD')
				this.timeCycle = [startTime, endTime]
				this.intervalDay = res.coordinateCycleDay
			})
		},
		handleLocke(e, row) {
			this.$confirm(`确定将该设备${e ? '进行' : '取消'}坐标锁定？`).then(() => {
				const params = {
					deviceId: row.deviceId,
					coordinateLocke: e ? 1 : 0,
				}
				this.loading = true
				apiCoordinateLocke(params)
					.then(() => {
						this.$message.success(`${e ? '' : '取消'}坐标锁定成功`)
						this.getList(1, apiCoordinateList, null)
					})
					.finally(() => {
						this.loading = false
					})
			})
		},
		handleSelectionChange(list) {
			this.selection = list
		},
		toDetail(row) {
			this.$router.push({
				path: '/device/detail',
				query: {
					deviceId: row.deviceId,
					deviceType: row.deviceTypeId,
				},
			})
		},
		handleReset() {
			this.form = this.$options.data().form
			const { tenantType } = this.$store.state.user.userInfo
			this.$set(this.form, 'tenantType', tenantType)
			this.getList(1, apiCoordinateList, null)
		},
		apiCoordinateList,
		handleUpdate() {
			const dataList = this.selection.map(({ deviceNo, deviceId, simNo }) => {
				return { deviceNo, deviceId, simNo }
			})
			this.loading = true
			apiCoordinateUpdate({ dataList })
				.then(data => {
					if (data.length) {
						this.failList = data
						this.showFailDialog = true
					} else {
						this.$message.success('更新坐标成功')
						this.clearSelection()
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		clearSelection() {
			this.getList(this.page.current, apiCoordinateList, null)
			this.selection = []
			this.failList = []
			this.$refs.tableRef.clearCheckTableSelection()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.content {
	flex: 1;
	height: 0;
	display: flex;
	.left {
		width: 300px;
		height: 100%;
		padding: 20px 12px;
		border-right: 1px solid #f1f1f1;
	}
	::v-deep .run-main {
		height: 100%;
		width: 0;
	}
}
</style>
