<template>
	<gc-dialog
		title="更新结果"
		ok-text="确 定"
		:show.sync="visible"
		:append-to-body="true"
		:show-cancel-btn="false"
		@ok="handleClose"
		@close="handleClose"
	>
		<div class="content">
			<div class="total">
				成功
				<span class="green bold">{{ totalList.length - failList.length }}</span>
				条， 失败
				<span class="red bold">{{ failList.length }}</span>
				条， 以下设备更新失败
			</div>
			<gc-table :columns="columns" :table-data="failList"></gc-table>
		</div>
	</gc-dialog>
</template>

<script>
export default {
	name: 'UpdateFailDialog',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		totalList: {
			type: Array,
			default: () => [],
		},
		failList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
				},
				{
					key: 'errorMessage',
					name: '失败原因',
				},
			],
		}
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	methods: {
		handleClose() {
			this.visible = false
			this.$emit('close')
		},
	},
}
</script>
<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
}
.content .total {
	margin-top: 10px;
	flex: 0 0 30px;
	.green {
		color: green;
	}
	.red {
		color: red;
	}
	.bold {
		font-weight: bold;
		font-size: 16px;
		margin: 0 5px;
	}
}
</style>
