import request from '@/utils/request'

// 坐标列表查询
export const apiCoordinateList = data => {
	return request({
		url: '/monitor/ledger/coordinate/list',
		method: 'POST',
		data,
	})
}

// 获取坐标定时刷新任务周期查询
export const apiCoordinateCycle = data => {
	return request({
		url: '/monitor/ledger/coordinate/cycle',
		method: 'POST',
		data,
	})
}

// 更新坐标
export const apiCoordinateUpdate = data => {
	return request({
		url: '/monitor/ledger/coordinate/update',
		method: 'POST',
		data,
	})
}

// 锁定坐标
export const apiCoordinateLocke = data => {
	return request({
		url: '/monitor/ledger/coordinate/coordinateLocke',
		method: 'POST',
		data,
	})
}
